.campaign{

	.page-header{
		height: 256px;
	}

	&__leadTxt{
		margin: 35px 0 45px;
		padding: 0 15px;

		.inner{
			margin: 0 auto;
			padding: 0;
			width: 100%;
			max-width: 900px;
		}

		p{
			line-height: 1.92;
		}
	}

	&__block{
		margin: 50px 0;
		padding: 0 15px;

		.inner{
			margin: 0 auto;
			padding: 0;
			width: 100%;
			max-width: 900px;
		}

		figure{
			margin: 30px 0;
		}

		figcaption{
			margin-bottom: 20px;
			font-size: 18px;
			font-weight: 700;
			text-align: center;
		}
	}
}
@media screen and (min-width:768px){
	.campaign{

		.page-header{
			height: 320px;
		}

		&__leadTxt{
			margin: 82px 0 70px;

			p{
				text-align: center;
			}
		}

		&__block{
			margin: 80px 0;
			padding: 0 15px;

			.inner{
				margin: 0 auto;
				padding: 0;
				width: 100%;
				max-width: 900px;
			}

			.txtarea{
				margin: 0 auto;
				width: 100%;
				max-width: 680px;
				text-align: center;
			}
	
			figure{
				margin: 60px 0;
				text-align: center;
			}
	
			figcaption{
				margin-bottom: 40px;
				font-size: 24px;
				line-height: 1.5;
			}
		}
	}
}