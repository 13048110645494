.campaign .page-header {
  height: 256px;
}
.campaign__leadTxt {
  margin: 35px 0 45px;
  padding: 0 15px;
}
.campaign__leadTxt .inner {
  margin: 0 auto;
  padding: 0;
  width: 100%;
  max-width: 900px;
}
.campaign__leadTxt p {
  line-height: 1.92;
}
.campaign__block {
  margin: 50px 0;
  padding: 0 15px;
}
.campaign__block .inner {
  margin: 0 auto;
  padding: 0;
  width: 100%;
  max-width: 900px;
}
.campaign__block figure {
  margin: 30px 0;
}
.campaign__block figcaption {
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
}

@media screen and (min-width: 768px) {
  .campaign .page-header {
    height: 320px;
  }
  .campaign__leadTxt {
    margin: 82px 0 70px;
  }
  .campaign__leadTxt p {
    text-align: center;
  }
  .campaign__block {
    margin: 80px 0;
    padding: 0 15px;
  }
  .campaign__block .inner {
    margin: 0 auto;
    padding: 0;
    width: 100%;
    max-width: 900px;
  }
  .campaign__block .txtarea {
    margin: 0 auto;
    width: 100%;
    max-width: 680px;
    text-align: center;
  }
  .campaign__block figure {
    margin: 60px 0;
    text-align: center;
  }
  .campaign__block figcaption {
    margin-bottom: 40px;
    font-size: 24px;
    line-height: 1.5;
  }
}